import React from 'react'

export const Cards = ({ color, titulo, numero, icono, clases }) => {


     return (
          <div className={clases} style={{
               backgroundColor: color, width: '12rem', height: '12rem'
          }} >
               <div className="card-body ">
                    <h5 className='class-title'>{numero} {titulo}</h5>
                    <span className='material-symbols-outlined'>
                         {icono}
                    </span>

               </div>

          </div >
     )
}
