import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

export const Header = () => {

     const [menuactivo, setmenuActivo] = useState(false)

     const encabezadoRef = useRef()

     useEffect(() => {
          const handleScroll = () => {
               const encabezado = encabezadoRef.current
               const { y } = encabezado.getBoundingClientRect()

               if (y <= 0) {
                    encabezado.classList.add('sticky-top')
                    encabezado.classList.add('pegajoso')
                    encabezado.classList.replace('container', 'container-fluid')
               } else {
                    encabezado.classList.remove('sticky-top')
                    encabezado.classList.remove('pegajoso')
                    encabezado.classList.replace('container-fluid', 'container')
               }
          }
          window.addEventListener('scroll', handleScroll)
          return () => {
               window.removeEventListener('scroll', handleScroll)
          }
     }, [])



     const offCanvaMenu = (e) => {
          setmenuActivo(!menuactivo)
     }

     const handleNavegation = () => {
          setmenuActivo(false)
     }




     return (
          <>
               <div className='container ' ref={encabezadoRef}>
                    <header className='navbar navbar-light' >
                         <div className='divLogo'>
                              <a href='/'><img className='logoNav' src='./img/isotipo.png' alt="isotipo sumarse" /><span className='marcaNav'>Red SumaRSE <b>COAHUILA</b></span></a>
                         </div>
                         <div onClick={(e) => offCanvaMenu(e)} className='iconoMenu'>
                              {!menuactivo ? <span className="material-symbols-outlined ">
                                   menu
                              </span> :
                                   <span className="material-symbols-outlined cerrarMenu">
                                        close
                                   </span>}
                         </div>
                    </header>
                    <hr />
               </div>

               <div className={menuactivo ? 'menuOffCanvas activo' : 'menuOffCanvas'}>
                    <div className='listMenu'>
                         <ul>
                              <li><Link to='/' onClick={handleNavegation} className="linkMenu"> Inicio </Link></li>
                              <li><Link to='/AboutUs' onClick={handleNavegation} className="linkMenu"> Nosotros </Link></li>
                              <li><Link to='/AboutUs' onClick={handleNavegation} className="linkMenu"> Iniciativa </Link></li>                              
                              <li><Link to='/AboutUs' onClick={handleNavegation} className="linkMenu"> Contacto </Link></li>
                         </ul>
                    </div>
               </div>
          </>


     )
}
