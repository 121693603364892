import React from 'react'
import { CarrouselAliados } from './CarrouselAliados'
import { CarrouselMiembros } from './CarrouselMiembros'
import { CarrouselMiembros2 } from './CarrouselMiembros2'

export const Aliados = () => {
  return (
    <>
    <div className='infoAliados mt-5'>
      <h2 className='tituloObjetivos'>¡GRACIAS!</h2>
      <p>Red sumaRSE Coahuila reconoce el gran apoyo de las empresas Miembro y Aliadas que nos fortalcen e impulsan a hacer día tras día lo mejor que somos...</p>

    </div>

    <h3 className='ms-5 mt-5'>Aliados de la Red</h3>
    <hr/>
    <CarrouselAliados/>
    <h3 className='ms-5 mt-5'>Miembros de la Red</h3>
    <hr/>
    <CarrouselMiembros/>
    <CarrouselMiembros2 className='mb-5'/>
    </>
  )
}
