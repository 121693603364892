import React from 'react'

export const Slider = () => {

     return (
          <>
               <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                         <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                         <div className="carousel-item active">
                              <img src="./img/banners/3.jpg" className="d-block w-100 imagenSlide" alt="..." />
                              <div className="carousel-caption infoBanner d-md-block">
                                   <h5 className='tituloImagen'>BRIGADAS JUVENILES 2019</h5>
                                   <p className='textoImagen'>Cierre de actividades en campo</p>
                                   <button className='botonImagen'>Más Información</button>
                              </div>
                         </div>
                         <div className="carousel-item">
                              <img src="./img/banners/2.jpg" className="d-block w-100 imagenSlide" alt="..." />
                              <div className="carousel-caption infoBanner  d-md-block">
                              <h5 className='tituloImagen'>BRIGADAS JUVENILES 2019</h5>
                                   <p className='textoImagen'>Cierre de actividades en campo</p>
                                   <button className='botonImagen'>Más Información</button>
                              </div>
                         </div>
                         <div className="carousel-item">
                              <img src="./img/banners/1.jpg" className="d-block w-100 imagenSlide" alt="..." />
                              <div className="carousel-caption infoBanner  d-md-block">
                              <h5 className='tituloImagen'>BRIGADAS JUVENILES 2019</h5>
                                   <p className='textoImagen'>Cierre de actividades en campo</p>
                                   <button className='botonImagen'>Más Información</button>
                              </div>
                         </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                         <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                         <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                         <span className="carousel-control-next-icon" aria-hidden="true"></span>
                         <span className="visually-hidden">Next</span>
                    </button>
               </div>
          </>
     )
}
