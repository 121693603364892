import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export const CarrouselMiembros = () => {

    const items = [
        <img src="./img/Miembros/holcim.png" alt='holcim' role="presentation" />,
        <img src="./img/Miembros/aoc.png" alt='aoc' role="presentation" />,
        <img src="./img/Miembros/arca.png" alt='arca' role="presentation" />,
        <img src="./img/Miembros/samuel.png" alt='samuel' role="presentation" />,
        <img src="./img/Miembros/ucarolina.png" alt='ucarolina' role="presentation" />,
        <img src="./img/Miembros/colcholandia.png" alt='colcholandia' role="presentation" />,
        <img src="./img/Miembros/fordemex.png" alt='fordemex' role="presentation" />,
        <img src="./img/Miembros/GIS.png" alt='GIS' role="presentation" />,
        <img src="./img/Miembros/FRosario.png" alt='FRosario' role="presentation" />,
        
    ];
    return (
        <AliceCarousel mouseTracking 
        items={items}
        responsive={{ 1200: { items: 5 } }}
        infinite={true}
        autoPlay={true}
        animationDuration={5000}
        autoPlayInterval={0}
        animationEasingFunction="linear"
        disableButtonsControls={true}
        disableDotsControls={true}
        />
    )
}
