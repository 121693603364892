import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export const CarrouselMiembros2 = () => {

    const items = [
        <img src="./img/Miembros/easesores.png" alt='easesores' role="presentation" />,
        <img src="./img/Miembros/dimakers.png" alt='dimakers' role="presentation" />,
        <img src="./img/Miembros/agsal.png" alt='agsal' role="presentation" />,
        <img src="./img/Miembros/vanguardia.png" alt='vanguardia' role="presentation" />,
        <img src="./img/Miembros/coconsa.png" alt='coconsa  ' role="presentation" />,
        <img src="./img/Miembros/spectro.png" alt='spectro' role="presentation" />,
        <img src="./img/Miembros/ruba.png" alt='ruba' role="presentation" />,
        <img src="./img/Miembros/avemed.png" alt='avemed' role="presentation" />,
        <img src="./img/Miembros/managetech.png" alt='managetech' role="presentation" />,
        <img src="./img/Miembros/aprime.png" alt='aprime' role="presentation" />,
        <img src="./img/Miembros/ilmercato.png" alt='ilmercato' role="presentation" />,        
        <img src="./img/Miembros/harmony.png" alt='harmony' role="presentation" />,
    ];
    return (
        <AliceCarousel mouseTracking 
        items={items}
        responsive={{ 1200: { items: 5 } }}
        infinite={true}
        autoPlay={true}
        animationDuration={5000}
        autoPlayInterval={0}
        animationEasingFunction="linear"
        disableButtonsControls={true}
        disableDotsControls={true}
        />
    )
}
