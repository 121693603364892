import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export const CarrouselAliados = () => {

    const items = [
        <img src="./img/aliados/FMC_logo.png" alt='Fundacion Merced' role="presentation" />,
        <img src="./img/aliados/FOE_logo.png" alt='Fomento de oportunidades educativas' role="presentation" />,
        <img src="./img/aliados/UTC_LOGO.png" alt='utc' role="presentation" />,
        <img src="./img/aliados/AIERA.png" alt='aiera' role="presentation" />,
        <img src="./img/aliados/CANCHAM_logo.png" alt='cancham' role="presentation" />,
        <img src="./img/aliados/CANIRAC.png" alt='Canirac' role="presentation" />,
        <img src="./img/aliados/CIAC.png" alt='ciac' role="presentation" />,
        <img src="./img/aliados/Comimsa_ovtt.png" alt='Cominsa' role="presentation" />,
        <img src="./img/aliados/Upra.png" alt='upra' role="presentation" />,
        <img src="./img/aliados/PROFAUNA.png" alt='Profauna' role="presentation" />,
        <img src="./img/aliados/saltillo_logo.png" alt='Saltillo' role="presentation" />,
    ];
    return (
        <AliceCarousel mouseTracking 
        items={items}
        responsive={{ 1200: { items: 5 } }}
        infinite={true}
        autoPlay={true}
        animationDuration={5000}
        autoPlayInterval={0}
        animationEasingFunction="linear"
        disableButtonsControls={true}
        disableDotsControls={true}
        />
    )
}
