import React from 'react'
import { AboutHome } from './AboutHome'
import { Aliados } from './Aliados'

import { Footer } from './Footer'
import { Objetivos } from './Objetivos'
import { Slider } from './Slider'

export const HomeScreen = () => {
     return (
          <>
               <Slider />
               <AboutHome />
               <Objetivos />
               <Aliados />                
               <Footer/>
          </>
     )
}
