import React from 'react'
import { Link } from 'react-router-dom'

export const AboutHome = () => {
     return (
          <div className='aboutHome-container container mt-5'>

               <div className='tituloAboutContainer'>
                    <div className='lineaAbout'></div>
                    <h2>Sobre la red</h2>
                    <div className='lineaAbout'></div>
               </div>


               <div className='contenidoAbout mb-5 '>
                    <div className='textoAbout'>
                         <h3>Historia</h3>
                         <p>RED SUMARSE COAHUILA nació de la intención de unir voluntades de empresas,
                              con el compromiso consciente y congruente de cumplir íntegramente con la finalidad del negocio responsable,
                              considerando las expectativas económicas, sociales y ambientales de todas las partes interesadas,
                              para así contribuir a la construcción del bien común.</p>
                    </div>

                    <div className='imageAboutContainer'>
                         <div className='imageAbout'>
                              <img className='imagen' src='./img/imagenHistoria.png' alt='timeline' />
                         </div>
                         <Link className='btn botonAboutHome' to='/AboutUs'> Conoce más</Link>
                    </div>
               </div>

               <figure className='mt-5'>
                    <blockquote className="blockquote">
                         <p>Donde hay unidad siempre hay victoria.</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                         <cite title="Source Title">Publilius Syrus</cite>
                    </figcaption>
               </figure>




          </div>
     )
}
