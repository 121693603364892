export const tarjetasInfo = [
     {
          color: '#E5233D',
          titulo: "FIN DE LA POBREZA",
          numero: 1,
          icono: 'family_restroom',
          class: ''
     },
     {
          color: '#DDA73A',
          titulo: "HAMBRE CERO",
          numero: 2,
          icono: 'soup_kitchen',
          class: ''
     },
     {
          color: '#4DA047',
          titulo: "SALUD Y BIENESTAR",
          numero: 3,
          icono: 'monitor_heart',
          class: ''
     },
     {
          color: '#C7212F',
          titulo: "EDUCACION DE CALIDAD",
          numero: 4,
          icono: 'school',
          class: ''
     },
     {
          color: '#EF402D',
          titulo: "IGUALDAD DE GENERO",
          numero: 5,
          icono: 'transgender',
          class: ''

     },
     {
          color: '#28BEE7',
          titulo: "AGUA LIMPIA Y SANEAMIENTO",
          numero: 6,
          icono: 'local_drink',
          class: ''
     },
     {
          color: '#FBC412',
          titulo: "ENERGIA ASEQUIBLE Y NO CONTAMINANTE",
          numero: 7,
          icono: 'solar_power',
          class: ''
     },
     {
          color: '#A31C44',
          titulo: "TRABAJO DESCENTE Y CRECIMIENTO ECONOMICO",
          numero: 8,
          icono: 'monitoring',
          class: ''
     },
     {
          color: '#F26A2E',
          titulo: "INDUSTRIA INNOVACION E INFRAESTRUCTURA",
          numero: 9,
          icono: 'precision_manufacturing',
          class: ''
     },
     {
          color: '#DE1768',
          titulo: "REDUCCION DE LAS DESIGUALDADES",
          numero: 10,
          icono: 'graphic_eq',
          class: ''
     },
     {
          color: '#F89D2A',
          titulo: "CIUDADES Y COMUNIDADES SOSTENIBLES",
          numero: 11,
          icono: 'apartment',
          class: ''
     },
     {
          color: '#BF8D2C',
          titulo: "PRODUCCION Y CONSUMO RESPONSABLE",
          numero: 12,
          icono: 'all_inclusive',
          class: ''
     },
     {
          color: '#407F46',
          titulo: "ACCION POR EL CLIMA",
          numero: 13,
          icono: 'public',
          class: ''
     },
     {
          color: '#1F97D4',
          titulo: "VIDA SUMARINA",
          numero: 14,
          icono: 'waves',
          class: ''
     },
     {
          color: '#58BB46',
          titulo: "VIDA DE ECOSISTEMAS TERRESTRES",
          numero: 15,
          icono: 'Nature',
          class: ''
     },
     {
          color: '#136A9F',
          titulo: "PAZ JUSTICIA E INSTITUCIONES SOLIDAS",
          numero: 16,
          icono: 'handshake',
          class: ''
     },
     {
          color: '#15496B',
          titulo: "ALIANZA PARA LOGRAR LOS OBJETIVOS",
          numero: 17,
          icono: 'diversity_2',
          class: ''
     },

]
