import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { tarjetasInfo } from '../../auxiliar/cardsInfo'
import { Cards } from './Cards'

export const Objetivos = () => {

     const objetivoref = useRef()




     const [infomacionCard, setinfomacionCard] = useState(tarjetasInfo)


     useEffect(() => {

          const handleScroll = () => {

               const divObjetivos = objetivoref.current
               const { y } = divObjetivos.getBoundingClientRect()

               const infoTarjetas = tarjetasInfo.map(tarjeta => {
                    if (tarjeta.numero === 4 || tarjeta.numero === 8 || tarjeta.numero === 11 || tarjeta.numero === 13) {
                         return { ...tarjeta, class: 'card tajetaObjetivo ' }
                    }
                    if (y <= 1000) {

                         return { ...tarjeta, class: 'card tajetaObjetivo opacity' }
                    }

                    return { ...tarjeta, class: 'card tajetaObjetivo' }

               })


               setinfomacionCard(infoTarjetas)

          }
          window.addEventListener('scroll', handleScroll)


          return () => {
               window.removeEventListener('scroll', handleScroll)
          }
     }, [])





     return (
          <div ref={objetivoref}>

               <h2 className='mt-5 tituloObjetivos'><span className="material-symbols-outlined">
                    compost
               </span>OBJETIVOS</h2>
               <h2 className='subTituloObjetivos'>DE DESARROLLO SOSTENIBLE</h2>
               <div className='ObjetivosHome mt-5'>
                    {infomacionCard.map(tarjeta => {

                         return (
                              <Cards clases={tarjeta.class} color={tarjeta.color} titulo={tarjeta.titulo} numero={tarjeta.numero} icono={tarjeta.icono} key={tarjeta.numero} />
                         )
                    })}
                    <Link className='btn mt-5' to='/iniciativas'>Ver Informacion de las Iniciativas</Link>
               </div>
               
          </div>
     )
}
