import React from 'react'
import { Route, Routes } from 'react-router';
import { About } from '../components/about/About';
import { Header } from '../components/general/Header';
import { HomeScreen } from '../components/home/HomeScreen';

export const RouterApp = () => {
     return (
          <>
               <div className='contacto-rapido'>
                    <div className='container contacto-rapido'>
                         <a href='#'>
                              <span className="material-symbols-outlined">
                                   phone_in_talk
                              </span>
                              <span> +(52) 844 439 2866</span>
                         </a>

                         <a href='#' className='donar'>Quiero Apoyar</a>
                    </div>

               </div>
               <Header />
               <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/AboutUs" element={<About />} />
               </Routes>
          </>
     )
}
